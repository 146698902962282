import React, { useContext, createContext } from "react";
import axios from "axios";

const DatastoreContext = createContext();

export const useDatastore = () => {
  return useContext(DatastoreContext);
};

export const DatastoreProvider = ({ children }) => {
  async function getUserEntry(currentUser, token) {
    try {
      const dataResponse = await axios.get(
        process.env.REACT_APP_PUBLIC_API_URL + "/apiusers/" + currentUser,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return dataResponse.data[0];
    } catch (error) {
      console.error(error);
    }
  }

  async function getIncidentData(token) {
    try {
      const dataResponse = await axios.get(
        process.env.REACT_APP_PUBLIC_API_URL + "/apiincidents",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let incidents = [];
      for (let i = 0; i < dataResponse.data.length; i++) {
        incidents.push({
          id: dataResponse.data[i].id,
          value: dataResponse.data[i].name_group,
          label: dataResponse.data[i].name,
        });
      }

      return incidents;
    } catch (error) {}
  }

  const addCrimeEntry = (token, formData, addCrimeEntryCallback) => {
    try {
      axios
        .post(
          process.env.REACT_APP_PUBLIC_API_URL + "/apicrimes/create",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(addCrimeEntryCallback)
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const value = {
    getUserEntry,
    addCrimeEntry,
    getIncidentData,
    // addEntry,
    // getAllEntries,
    // getEntry,
    // updateEntry,
    // deleteEntry,
    // uploadFile,
    // getFile,
  };

  return (
    <DatastoreContext.Provider value={value}>
      {children}
    </DatastoreContext.Provider>
  );
};
