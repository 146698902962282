import React, { useEffect, useState } from "react";

const Clock = ({ size }) => {
  const [clockState, setClockState] = useState();
  const [dateState, setDateState] = useState();

  useEffect(() => {
    setInterval(() => {
      const date = new Date();
      setDateState(date.toLocaleDateString());
      setClockState(date.toLocaleTimeString());
    }, 1000);
  }, []);

  return (
    <div style={{ fontSize: size }}>
      {dateState} - {clockState}
    </div>
  );
};

export default Clock;
