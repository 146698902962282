import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import MainButton from "../../components/Buttons/MainButton";
import PublicLogo from "../../components/Images/PublicLogo";
import packageJSON from "../../../package.json";
import { useDatastore } from "../../Context/DatastoreContext";
import { useAuth } from "../../Context/AuthContext";

const Home = ({
  theme,
  currentUser,
  getUserDetailsHandler,
  userDetails,
  userCurrentPosition,
}) => {
  const { getUserEntry } = useDatastore();
  const { token } = useAuth();

  const getCurrentUserDetailsHandler = async () => {
    try {
      const currentUserDetails = await getUserEntry(currentUser, token);
      getUserDetailsHandler(currentUserDetails, false);
    } catch (error) {
      console.error(error);
      getUserDetailsHandler(null, true);
    }
  };

  useEffect(() => {
    getCurrentUserDetailsHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (userDetails === undefined || userDetails === null) return null;
  if (userCurrentPosition === undefined || userCurrentPosition === null)
    return null;

  return (
    <Container className="mt-3" style={{ marginBottom: "15vh" }}>
      <Row>
        <Col>
          <Row className="w-100 mx-0 mb-4">
            <Col>
              <PublicLogo src={theme?.icons?.main} />
            </Col>
          </Row>

          <Row className="mb-5">
            <Col>
              <Link to="/report-crime">
                <MainButton
                  style={{
                    backgroundColor: theme?.button?.main,
                  }}
                  className="radio-event-types"
                >
                  Report a Crime
                </MainButton>
              </Link>
            </Col>
          </Row>

          {/* {userDetails && userDetails.role_id === "2" && (
            <Row>
              <Col>
                <Link to="/all-crimes">
                  <MainButton
                    style={{
                      backgroundColor: theme?.color?.primary,
                      border: `1px solid ${theme?.button?.main}`,
                      color: theme?.color?.third,
                    }}
                    className="radio-event-types"
                  >
                    Review Crimes
                  </MainButton>
                </Link>
              </Col>
            </Row>
          )} */}

          <Row>
            <Col className="mt-5 text-center">
              <Link to="/" style={{ textDecoration: "none", color: "#fff" }}>
                v{packageJSON.version}
              </Link>
              <div style={{ textDecoration: "none", color: "#fff" }}>
                <strong>Logged is as: </strong>{" "}
                {`${userDetails.name} ${userDetails.surname}`}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
