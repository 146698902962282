export const Validation = (string) => {
  return new Promise((resolve, reject) => {
    if (string) {
      if (string.length < 7) {
        reject({
          isNotValid: true,
          message: "Password length should be more than 7",
        });
      } else {
        resolve({ isNotValid: false, message: "Password is valid" });
      }
    }
  });
};
