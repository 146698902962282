import { useEffect, useState, useCallback } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { Alert, Container, Row, Col } from "react-bootstrap";
import { useAuth } from "../../../Context/AuthContext";

import { DatastoreProvider } from "../../../Context/DatastoreContext";

import { useJsApiLoader } from "@react-google-maps/api";
// import { useGeolocated } from "react-geolocated";

import NavigationBar from "../../Navigation/NavigationBar/NavigationBar";

import PrivateRoute from "./PrivateRoute";
import Location from "../../../lib/services/currentposition";
import CreateEvent from "../../../pages/private/CreateEvent/CreateEvent";
//import AllEvents from "../../../pages/private/AllEvents/AllEvents";
//import ViewEvent from "../../../pages/private/ViewEvent/ViewEvent";
import Home from "../../../pages/private/Home";
//import Debug from "../../../pages/private/Debug/Debug";

const PrivateRoutes = ({ theme }) => {
  const { logout, currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [userCurrentPosition, setUserCurrentPosition] = useState(null);
  const [userPositionError, setUserPositionError] = useState(null);
  const [activeMap, setActiveMap] = useState(null);
  const [error, setError] = useState("");
  const [userDetails, setUserDetails] = useState(null);

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script", 
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY, 
  });

  const getCurrentPosition = async () => {
    try {
      let locationMap = {};
      const currentPosition = await Location.getCurrentPosition();
      locationMap.trueposition = true;
      locationMap.Accuracy = currentPosition.accuracy;
      locationMap.Altitude = currentPosition.altitude;
      locationMap.Latitude = currentPosition.latitude;
      locationMap.Longitude = currentPosition.longitude;
      setUserCurrentPosition(locationMap);
    } catch (error) {
      setUserCurrentPosition({
        trueposition: false,
        Accuracy: 0,
        Latitude: -29.555,
        Longitude: 25.107,
      });
      setUserPositionError(error);
      setError("Cannot get current position");
    }
  };


  // const { coords, isGeolcationAvailable, isGeolocationEnabled, getPosition } =
  //   useGeolocated({
  //     positionOptions: {
  //       enableHighAccuracy: true,
  //     },
  //     watchPosition: true,
  //     userDecisionTimeout: 10000,

  //     isOptimisticGeolocationEnabled: false,
  //   });

  // const getCurrentPosition = () => {
  //   console.log(isGeolocationEnabled);
  //   // getPosition();

  //   if (coords) {
  //     console.log(coords.latitude);
  //     let locationMap = {};
  //     locationMap.trueposition = true;
  //     locationMap.Accuracy = coords.accuracy;
  //     locationMap.Altitude = coords.altitude;
  //     locationMap.Latitude = coords.latitude;
  //     locationMap.Longitude = coords.longitude;
  //     setUserCurrentPosition(locationMap);
  //   }
  // };


  const onLoad = useCallback(function callback(map) {
    if (userCurrentPosition) {
      const { Latitude, Longitude, Address } = userCurrentPosition;
      const bounds = new window.google.maps.LatLngBounds({
        lat: Latitude,
        lng: Longitude,
        address: Address
      });
      map.fitBounds(bounds);
      setActiveMap(map);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setActiveMap(null);
  }, []);

  const handleLogout = async () => {
    setError("");
    try {
      await logout();
      navigate("/login");
    } catch (e) {
      console.log(e);
      setError("Failed to Log Out");
    }
  };

  const getUserDetailsHandler = (details, error) => {
    if (!error) {
      setUserDetails({
        user_id: details.id,
        role_id: details.role_id,
        organisation_id: details.organisation_id,
        name: details.name,
        surname: details.surname,
        email: details.email,
        active: details.active,
      });
    } else {
      setError("Cannot get user details");
    }
  };

  useEffect(() => {
    if (
      location.pathname === "/login" ||
      location.pathname === "/signup" ||
      location.pathname === "/forgot-password"
    ) {
      navigate("/");
    } else {
      getCurrentPosition();
    }
  }, [location.pathname, navigate]);

  return (
    <DatastoreProvider>
      <Container
        className="d-flex justify-content-center pt-4"
        style={{
          minHeight: "100vh",
          backgroundColor: "#0e0f14",
          maxWidth: "400px",
        }}
        fluid
      >
        <Row className="w-100 d-flex justify-content-center">
          <Row>
            <Col className="w-100">
              <NavigationBar theme={theme} handleLogout={handleLogout} />
              <Routes className="w-100">
                <Route exact path="/" element={<PrivateRoute />}>
                  <Route
                    exact
                    path="/"
                    element={
                      <Home
                        theme={theme}
                        currentUser={currentUser}
                        getUserDetailsHandler={getUserDetailsHandler}
                        userDetails={userDetails}
                        userCurrentPosition={userCurrentPosition}
                      />
                    }
                  />
                </Route>

                {/* <Route path="/debug" element={<PrivateRoute />}>
                  <Route
                    path="/debug"
                    element={
                      <Debug
                        theme={theme}
                        isLoaded={isLoaded}
                        loadError={loadError}
                        userCurrentPosition={userCurrentPosition}
                        userPositionError={userPositionError}
                        currentUser={currentUser}
                        userDetails={userDetails && userDetails}
                      />
                    }
                  />
                </Route> */}

                <Route path="/report-crime" element={<PrivateRoute />}>
                  <Route
                    path="/report-crime"
                    element={
                      <CreateEvent
                        theme={theme}
                        userDetails={userDetails}
                        userCurrentPosition={userCurrentPosition}
                        isLoaded={isLoaded}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                      />
                    }
                  />
                </Route>

                {/* <Route path="/all-crimes" element={<PrivateRoute />}>
                  <Route
                    path="/all-crimes"
                    element={
                      <AllEvents
                        theme={theme}
                        userCurrentPosition={userCurrentPosition}
                        isLoaded={isLoaded}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        currentUser={currentUser}
                      />
                    }
                  />
                </Route> */}

                {/* <Route path="/view-crime" element={<PrivateRoute />}>
                  <Route
                    path="/view-crime"
                    element={
                      <ViewEvent
                        theme={theme}
                        userCurrentPosition={userCurrentPosition}
                        isLoaded={isLoaded}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                      />
                    }
                  />
                </Route> */}
              </Routes>
            </Col>
            {error && (
              <Col>
                <Alert variant="danger" className="text-center">
                  {error}
                </Alert>
              </Col>
            )}
          </Row>
        </Row>
      </Container>
    </DatastoreProvider>
  );
};

export default PrivateRoutes;
