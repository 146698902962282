import { Button } from "react-bootstrap";

const MainButton = (props) => {
  return (
    <Button {...props} className="main-button">
      {props.children}
    </Button>
  );
};

export default MainButton;
