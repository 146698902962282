import React, { useRef, useState, useEffect } from "react";
import { Alert, Row, Form, Container, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { PhoneInput } from "react-rainbow-components";

import { useAuth } from "../../../Context/AuthContext";

import MainButton from "../../../components/Buttons/MainButton";
import PublicLogo from "../../../components/Images/PublicLogo";
import { Validation } from "../../../lib/validation";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const Signup = ({ theme }) => {
  const { signupUser, getOrganisationData } = useAuth();
  const navigate = useNavigate();
  // const { getOrganisationData } = useDatastore();

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const organisationRef = useRef();

  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isNotValid, setIsNotValid] = useState(true);
  const [isHidden, setIsHidden] = useState(false);
  const [phone, setPhone] = useState({ isoCode: "za" });
  const [organisation, setOrganisation] = useState();

  useEffect(() => {
    getOrganisations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function createSuccess() {
      await delay(3000);
      navigate(`/`);
    }

    if (success) {
      createSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    async function createError() {
      await delay(3000);
      setIsHidden(false);
      setError("");
      navigate(`/signup`);
    }

    if (error) {
      createError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const getOrganisations = async () => {
    let data = await getOrganisationData();
    setOrganisation(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    try {
      setError("");
      setLoading(true);
      const signUpDetails = {
        name: firstNameRef.current.value,
        surname: lastNameRef.current.value,
        email: emailRef.current.value,
        password: passwordRef.current.value,
        organisation: organisationRef.current.value,
        phone: phone,
      };
      signupUser(signUpDetails, signupUserCallback);
    } catch (e) {
      console.log(e);
      setError("Failed to create an account");
    }
    setLoading(false);
  };

  const signupUserCallback = (res) => {
    console.log(res.data.messages.success);
    if (res.data.status === 403) {
      setError(res.data.messages.success);
      setIsHidden(true);
    }

    if (res.data.status === 201) {
      setIsHidden(true);
      setSuccess(res.data.messages.success);
    }
  };

  const handleValidation = async (event) => {
    try {
      const validation = await Validation(event.target.value);
      setError("");
      setIsNotValid(validation.isNotValid);
    } catch (e) {
      setError(e.message);
      setIsNotValid(e.isNotValid);
    }
  };

  if (organisation === undefined) return null;

  return (
    <Container className="mt-5" style={{ marginBottom: "15vh" }}>
      <Row className="w-100 mx-0 mb-4">
        <Col>
          <PublicLogo src={theme?.icons?.main} />
        </Col>
      </Row>
      <Row>
        <Col>
          {error && <Alert variant="danger">{error}</Alert>}
          {success && (
            <Alert variant="success" className="text-center">
              {success}
            </Alert>
          )}
          <Form onSubmit={handleSubmit} hidden={isHidden}>
            <Form.Group id="email-group" className="mb-3">
              <Form.Control
                className="public-input-field"
                type="text"
                ref={firstNameRef}
                placeholder="First Name"
                required
              />
            </Form.Group>
            <Form.Group id="email-group" className="mb-3">
              <Form.Control
                className="public-input-field"
                type="text"
                ref={lastNameRef}
                placeholder="Last Name"
                required
              />
            </Form.Group>

            <div className="mb-3">
              <PhoneInput
                placeholder="Enter your phone number"
                onChange={setPhone}
                value={phone}
                required={true}
              />
            </div>

            <Form.Group id="organisation-group" className="mb-3">
              <Form.Select ref={organisationRef}>
                {organisation.map((org) => (
                  <option key={org.id} value={org.id}>
                    {org.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group id="email-group" className="mb-3">
              <Form.Control
                className="public-input-field"
                type="email"
                ref={emailRef}
                placeholder="Email"
                required
              />
            </Form.Group>
            <Form.Group id="password-group" className="mb-3">
              <Form.Control
                onChange={handleValidation}
                className="public-input-field"
                type="password"
                ref={passwordRef}
                placeholder="Password"
                required
              />
            </Form.Group>
            <Form.Group id="password-confirm-group" className="mb-3">
              <Form.Control
                className="public-input-field"
                type="password"
                ref={passwordConfirmRef}
                placeholder="Confirm Password"
                required
              />
            </Form.Group>
            <MainButton
              disabled={loading || isNotValid}
              className="w-100"
              type="submit"
              style={{
                backgroundColor: theme?.button?.main,
                border: `2px solid ${theme?.color?.third}`,
              }}
            >
              Sign Up
            </MainButton>
          </Form>
        </Col>
      </Row>
      <Row className="d-flex align-items-center justify-content-center mt-4 mb-1">
        <hr className="w-50" />
      </Row>
      <Row className="mb-4">
        <Col>
          <Link to="/login">
            <MainButton
              style={{
                backgroundColor: theme?.color?.third,
                border: `2px solid ${theme?.button?.main}`,
                color: theme?.button?.main,
              }}
            >
              Log In
            </MainButton>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default Signup;
