import React from "react";
import { Form } from "react-bootstrap";
import MainButton from "../../components/Buttons/MainButton";

const LoginForm = ({
  handleSubmit,
  cellphoneRef,
  passwordRef,
  theme,
  loading,
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group id="cellphone-group" className="mb-3">
        <Form.Control
          className="public-input-field"
          type="tel"
          ref={cellphoneRef}
          placeholder="Mobile Number"
          maxLength={10}
          required
        />
      </Form.Group>
      <Form.Group id="password-group" className="mb-3">
        <Form.Control
          className="public-input-field"
          type="password"
          ref={passwordRef}
          placeholder="Password"
          required
        />
      </Form.Group>
      <MainButton
        disabled={loading}
        type="submit"
        style={{
          backgroundColor: theme?.button?.main,
          border: `2px solid ${theme?.color?.third}`,
        }}
      >
        Log In
      </MainButton>
    </Form>
  );
};

export default LoginForm;
