import { useState, useEffect } from "react";

import Theme from "../lib/theme";
import { useAuth } from "../Context/AuthContext";
import "./App.css";

import PublicRoutes from "./Routes/Public/PublicRoutes";
import PrivateRoutes from "./Routes/Private/PrivateRoutes";

const App = () => {
  const { currentUser } = useAuth();

  const [theme, setTheme] = useState(null);

  useEffect(() => {
    setTheme(Theme);
  }, []);

  return (
    <>
      {!currentUser ? (
        <PublicRoutes theme={theme} />
      ) : (
        <PrivateRoutes theme={theme} />
      )}
    </>
  );
};

export default App;
