import React, { useRef, useState } from "react";
import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth } from "../../../Context/AuthContext";
import MainButton from "../../../components/Buttons/MainButton";
import PublicLogo from "../../../components/Images/PublicLogo";

const ForgotPassword = ({ theme }) => {
  const { resetPassword } = useAuth();
  const emailRef = useRef();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setMessage("");
      setError("");
      setLoading(true);
      await resetPassword(emailRef.current.value);
      setMessage("Check your email for further instructions");
    } catch (e) {
      console.log(e);
      setError("Failed to reset password");
    }
    setLoading(false);
  };

  return (
    <Container className="mt-5" style={{ marginBottom: "15vh" }}>
      <Row className="w-100 mx-0 mb-4">
        <Col>
          <PublicLogo src={theme?.icons?.main} />
        </Col>
      </Row>
      <Row>
        <Col>
          {error && <Alert variant="danger">{error}</Alert>}
          {message && <Alert variant="success">{message}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email-group" className="mb-3">
              <Form.Control
                className="public-input-field"
                type="email"
                ref={emailRef}
                placeholder="Email"
                required
              />
            </Form.Group>
            <MainButton
              disabled={loading}
              type="submit"
              style={{
                backgroundColor: theme?.button?.main,
                border: `2px solid ${theme?.color?.third}`,
              }}
            >
              Reset Password
            </MainButton>
          </Form>
        </Col>
      </Row>
      <Row className="d-flex align-items-center justify-content-center mt-4 mb-2">
        <hr className="w-50" />
      </Row>
      <Row className="mb-4">
        <Col>
          <Link to="/login">
            <MainButton
              style={{
                backgroundColor: theme?.color?.third,
                border: `2px solid ${theme?.button?.main}`,
                color: theme?.button?.main,
              }}
            >
              Log In
            </MainButton>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
