const Theme = {
  name: "Crime Map",
  icons: {
    main: "/images/CM_Logo_01.png",
  },
  button: {
    main: "#faa31b",
  },
  color: {
    background: "#101620",
    backgroundSecondary: "#808080",
    primary: "#faa31b",
    secondary: "#3F3F47",
    third: "#F0F2F5",
  },
  border: {
    radius: "15px",
    color: "#faa31b",
  },
  borderRadius: "10px",
};

export default Theme;
