import React, { useRef, useState } from "react";
import { Alert, Row, Container, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../Context/AuthContext";
import MainButton from "../../../components/Buttons/MainButton";
import PublicLogo from "../../../components/Images/PublicLogo";
import LoginForm from "../../../components/Forms/LoginForm";

const Login = ({ theme }) => {
  const { setUser } = useAuth();
  const navigate = useNavigate();

  const cellphoneRef = useRef();
  const passwordRef = useRef();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);

      setUser(cellphoneRef.current.value, passwordRef.current.value);

      navigate("/");
    } catch (e) {
      console.log(e);
      setError("Failed to log into an account");
    }
    setLoading(false);
  };

  return (
    <Container className="mt-5" style={{ marginBottom: "15vh" }}>
      <Row className="w-100 mx-0 mb-4">
        <Col>
          <PublicLogo src={theme?.icons?.main} />
        </Col>
      </Row>
      <Row className="mb-5">
        <Col>
          {error && <Alert variant="danger">{error}</Alert>}
          <LoginForm
            handleSubmit={handleSubmit}
            cellphoneRef={cellphoneRef}
            passwordRef={passwordRef}
            theme={theme}
            loading={loading}
          />
        </Col>
      </Row>
      <Row className="d-flex align-items-center justify-content-center mt-4 mb-2">
        <hr className="w-50" />
      </Row>
      <Row>
        <Col>
          <Link to="/signup">
            <MainButton
              style={{
                backgroundColor: theme?.color?.third,
                border: `2px solid ${theme?.button?.main}`,
                color: theme?.button?.main,
              }}
            >
              Sign Up
            </MainButton>
          </Link>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <div className="w-100 text-center mt-3">
            <Link to="/forgot-password" style={{ color: "#fff" }}>
              Forgot Password?
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
