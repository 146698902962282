let Module = {};

Module.getCurrentPosition = () => {
  return new Promise((resolve, reject) => {
    var options = {
      enableHighAccuracy: true,
      timeout: 50000,
      maximumAge: 100,
    };
    if (navigator.geolocation) {
      function success(pos) {
        var crd = pos.coords;
        resolve(crd);
      }

      function error(err) {
        reject(err);
      }

      navigator.geolocation.getCurrentPosition(success, error, options);
    } else {
      reject("navigator.geolocation is not available");
    }
  });
};

export default Module;
