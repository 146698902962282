import React, { useContext, useState, useEffect } from "react";
import axios from "axios";

const AuthContext = React.createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [userLogin, setUserLogin] = useState();
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState();

  useEffect(() => {
    if (userLogin === undefined) {
      return;
    }

    login(userLogin.cellphone, userLogin.password);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLogin]);

  const setUser = (cellphone, password) => {
    setUserLogin({ cellphone: cellphone, password: password });
  };

  const login = (cellphone, password) => {
    try {
      //axios login call
      axios
        .post(process.env.REACT_APP_PUBLIC_API_URL + "/apiusers/login", {
          cellphone: cellphone,
          password: password,
        })
        .then(loginCallback)
        .catch((e) => {
          console.log(e);
        });
      //callback that navigates
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const loginCallback = (res) => {
    if (res.data.id !== "") {
      setCurrentUser(res.data.id);
      setToken(res.data.token);
      localStorage.setItem("loginToken", res.data.token);
    } else {
      //ERROR
    }
  };

  const signupUser = (details, signupUserCallback) => {
    let phone = "";

    if (
      details.phone.countryCode === "+27" &&
      details.phone.phone.length < 10
    ) {
      phone = "0".concat(details.phone.phone);
    } else {
      phone = details.phone.phone;
    }

    try {
      axios
        .post(process.env.REACT_APP_PUBLIC_API_URL + "/apiusers/create", {
          organisation_id: details.organisation,
          name: details.name,
          surname: details.surname,
          email: details.email,
          cellphone: phone,
          password: details.password,
        })
        .then(signupUserCallback)
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  async function getOrganisationData() {
    try {
      const dataResponse = await axios.get(
        process.env.REACT_APP_PUBLIC_API_URL + "/apiorganisations"
      );
      console.log(dataResponse.data[0]);
      return dataResponse.data;
    } catch (error) {
      console.error(error);
    }
  }

  const logout = () => {
    let token = localStorage.getItem("loginToken");
    try {
      axios
        .post(
          process.env.REACT_APP_PUBLIC_API_URL + "/apiusers/logout",
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(logoutCallback)
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const logoutCallback = (res) => {
    console.log(res.data);
    localStorage.removeItem("loginToken");
    setCurrentUser();
    window.location.reload(false);
  };

  // const resetPassword = (email) => {
  //   return sendPasswordResetEmail(auth, email);
  // };

  useEffect(() => {
   
    const getUser = () => {
      let token = localStorage.getItem("loginToken");
      if(token === null) return
      try {
        axios
          .post(
            process.env.REACT_APP_PUBLIC_API_URL + "/apiusers/getUser",
            {},
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(getUserCallback)
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.error(error);
      }
    };

    const getUserCallback = (res) => {
      console.log(res, "FROM CALLBACK")
      if(res.statusText === "OK"){
        setCurrentUser(res.data[0].id);
        setToken(localStorage.getItem("loginToken"));
        setLoading(false);
      }
     
    };

    getUser();

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    currentUser,
    setUser,
    setToken,
    token,
    signupUser,
    getOrganisationData,
    login,
    logout,
    // resetPassword,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
