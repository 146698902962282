import React, { useEffect, useState, createRef, useRef } from "react";
import {
  Form,
  Image,
  Alert,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import { v4 } from "uuid";
import { useNavigate, Link } from "react-router-dom";
import Select from "react-select";
import { DateTimePicker, Spinner } from "react-rainbow-components";

//My calls
import { useDatastore } from "../../../Context/DatastoreContext";
import { useAuth } from "../../../Context/AuthContext";

import Clock from "../../../components/Clock/Clock";
import MainButton from "../../../components/Buttons/MainButton";
import MapMain from "../../../components/MapView/MapMain";
import PublicLogo from "../../../components/Images/PublicLogo";

import Geocode from "react-geocode"

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const CreateEvent = ({
  theme,
  userDetails,
  userCurrentPosition,
  isLoaded,
  onLoad,
  onUnmount,
}) => {
  // const { addEntry, uploadFile } = useFirestore();
  const { addCrimeEntry, getIncidentData } = useDatastore();
  const { token } = useAuth();
  const navigate = useNavigate();

  const mapRef = createRef();

  const [desc, setDesc] = useState(null);
  const [files, setFiles] = useState([]);
  const [isHidden, setIsHidden] = useState(false);

  const [timeDatePicker, setTimeDatePicker] = useState(new Date());
  const [crimeSelect, setCrimeSelect] = useState([]);

  const [logEvent, setLogEvent] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [mapDrag, setMapDrag] = useState(null);
  const [crimeData, setCrimeData] = useState(undefined);
  const [address, setAddress] = useState(undefined);
  // const [newCrimes, setNewCrimes] = useState(undefined);

  const [show, setShow] = useState(false);

  useEffect(() => {
    //getAddress(mapDrag);
    // getAddress2(userCurrentPosition.Latitude, userCurrentPosition.Longitude, "AIzaSyB_Sfqx8pziCE_npsTMjVu4u06AZ0Evlbo")
  }, [mapDrag]);

  useEffect(() => {
    async function createSuccess() {
      await delay(3000);
      navigate(`/`);
    }

    if (success) {
      createSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    async function createCancel() {
      await delay(3000);
      navigate(`/`);
    }

    if (cancel) {
      createCancel();
    }
  }, [cancel]);

  const getCrimes = async () => {
    const crimes = await getIncidentData(token);
    setCrimeData(crimes);
  };

  const imageUploadHandler = (event) => {
    let fileArray = [];

    const targetFiles = event.target.files;
    const targetFilesObject = [...targetFiles];

    for (let i = 0; i < targetFilesObject.length; i++) {
      fileArray.push({
        id: v4(),
        url: URL.createObjectURL(targetFilesObject[i]),
        file: targetFilesObject[i],
      });
    }

    setFiles([...files, ...fileArray]);
  };

  const removeSelectedImage = (event) => {
    event.preventDefault();
    console.log(event.target.id);
    setFiles(files.filter((file) => file.id !== event.target.id));
  };

  // const handleEventCreate = (entry) => {
  //   navigate(`/view-crime?id=${entry._id}`);
  // };

  //useRef
  const form = useRef();

  const handleOpenModal = () => setShow(true);
  const handleCloseModal = () => setShow(false);
  const handleCancel = () => {
    setShow(false);
    setCancel(true);
    setIsHidden(true);
  };

  //Geocode settings
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
  Geocode.setLocationType("ROOFTOP");

  const getAddress = (data) => {
    if(!data) return;
    let lat = data.lat;
    let long = data.lng;
    Geocode.fromLatLng(lat, long)
    .then((res) => {
      const address = res.results[0].formatted_address
      setAddress(address);
    },
    (error) => {
      Geocode.setLocationType("APPROXIMATE")
    });
  }

  // const [zip, setZip] = useState(undefined)

  // const getAddress2 = (lat, long, googleKey) => {
  //   let address;
  //   console.log(lat, long, googleKey)
  //   fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${googleKey}`)
  //   .then(res => res.json())
  //   .then(address => setZip(address))
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setShow(false);

    const formData = new FormData();

    let myDate = new Date(timeDatePicker);

    let incidentsData = {};

    for (let i = 0; i < crimeSelect.length; i++) {
      incidentsData = { ...incidentsData, [i]: crimeSelect[i].id };
    }

    if (files) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files[]", files[i].file);
      }
    }

    if(!myDate) return null;


    formData.append("user_id", userDetails.user_id);
    formData.append("organisation_id", userDetails.organisation_id);
    formData.append("report_date", myDate.toLocaleString('en-US', {
      hour12: false
    }));
    formData.append("coordinates_long", mapDrag.lng);
    formData.append("coordinates_lat", mapDrag.lat);
    formData.append("note", desc);
    formData.append("incidents", JSON.stringify(incidentsData));
    formData.append("coordinates_address", address);

    try {
      addCrimeEntry(token, formData, addCrimeEntryCallback);
    } catch (e) {
      console.log("An error occured", e);
      setError(e);
    }
  };

  const addCrimeEntryCallback = (res) => {
    console.log("log createEvent callback");
    console.log(res.data);
    if (res.data.error !== null) {
      setError("Something went wrong");
      navigate(`/`);
    }

    setSuccess(true);
    setIsHidden(true);
  };

  const sortArray = (arr) => {
    return arr.sort((a, b) => {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    });
  };

  useEffect(() => {
    if (crimeSelect.length > 0) {
      setLogEvent(false);
    } else {
      setLogEvent(true);
    }
  }, [crimeSelect]);

  useEffect(() => {
    getCrimes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (crimeData === undefined) return null;

  return (
    <Container className="mt-3" style={{ marginBottom: "15vh" }}>
      <Row>
        <Col>
          <Row>
            <Col>
              <PublicLogo src={theme?.icons?.main} />
            </Col>
            <Col>
              <h2 className="text-center mb-2">New Crime</h2>
            </Col>
          </Row>

          <Row style={{ marginTop: "-37px" }}>
            <Col>
              <Link to="/">
                <Image src="/images/icons/arrow_back.svg" />
              </Link>
            </Col>
          </Row>

          <div className="text-center mb-3">
            <Clock size="14px" />
          </div>
          {error && (
            <Alert variant="danger" className="text-center">
              {error}
            </Alert>
          )}
          {success && (
            <Alert variant="success" className="text-center">
              Event created
            </Alert>
          )}
          {cancel && (
            <Alert variant="primary" className="text-center">
              Event creation Cancelled
            </Alert>
          )}

          <Form
            onSubmit={handleSubmit}
            className="mt-4"
            encType="multipart/form-data"
            ref={form}
            hidden={isHidden}
          >
            <div className="mb-3">
              <DateTimePicker
                id="datetimepicker-1"
                className="rainbow-m-around_small"
                value={timeDatePicker}
                onChange={(value) => setTimeDatePicker(value)}
                formatStyle="large"
                locale="en-US"
                okLabel="OK"
                cancelLabel="CANCEL"
              />
            </div>

            <div style={{ marginBottom: "20px" }}>
              <Select
                options={sortArray(crimeData)}
                isMulti
                isSearchable
                placeholder="CRIME INCIDENT"
                onChange={(e) => setCrimeSelect(e)}
              />
            </div>

            <Form.Group className="mb-3" controlId="eventDesc">
              <Form.Control
                className="main-input"
                placeholder="NOTES:"
                as="textarea"
                rows={4}
                onChange={(e) => setDesc(e.target.value)}
              />
            </Form.Group>

            <input
              type="file"
              name="files"
              id="image-upload"
              multiple
              onChange={imageUploadHandler}
              hidden
            />

            <div className="map-view-container mb-4 w-100">
              {userCurrentPosition ? (
                <MapMain
                  zoom={15}
                  userCurrentPosition={userCurrentPosition}
                  isLoaded={isLoaded}
                  mapRef={mapRef}
                  onLoad={onLoad}
                  onUnmount={onUnmount}
                  mapDrag={mapDrag}
                  setMapDrag={setMapDrag}
                  mapContainerStyle={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              ) : (
                <div>
                  <Spinner size="large" type="arc" variant="brand" />
                </div>
              )}
            </div>

            <label
              className="main-button mb-3"
              style={{
                backgroundColor: theme?.color?.primary,
                border: `1px solid ${theme?.button?.main}`,
                color: theme?.color?.third,
              }}
              htmlFor="image-upload"
            >
              Upload Photo
            </label>

            {files !== undefined ? (
              files.map((file) => (
                <div
                  className="d-flex align-items-center justify-content-center mb-4"
                  key={file.id}
                >
                  <div>
                    <Image
                      src={file.url}
                      style={styles.imagePreview}
                      width="250px"
                      height="250px"
                      id={file.id}
                    />
                    <button
                      id={file.id}
                      onClick={removeSelectedImage}
                      style={styles.deleteButton}
                    >
                      Remove Image
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div></div>
            )}

            <MainButton
              style={{ backgroundColor: theme?.button?.main }}
              // type="submit"
              disabled={logEvent}
              onClick={handleOpenModal}
            >
              Create Crime Event
            </MainButton>
          </Form>
        </Col>
      </Row>
      <Modal
        show={show}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Log Crime Event</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center", fontSize: "18px" }}>
          Are you sue you want to report this crime?
        </Modal.Body>
        <Modal.Footer>
          <MainButton
            // variant="primary"
            onClick={handleSubmit}
            disabled={logEvent}
            style={{ backgroundColor: theme?.button?.main }}
          >
            Create
          </MainButton>
          <MainButton variant="danger" onClick={handleCancel}>
            Cancel
          </MainButton>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default CreateEvent;

const styles = {
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  imagePreview: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "15px",
  },
  deleteButton: {
    cursor: "pointer",
    padding: 3,
    marginTop: "5px",
    border: "none",
    width: "100%",
    borderRadius: "15px",
    background: "red",
    color: "white",
  },
};
