import React, { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import Signup from "../../../pages/public/Signup/Signup";
import Login from "../../../pages/public/Login/Login";
import ForgotPassword from "../../../pages/public/ForgotPassword/ForgotPassword";
import UnAuth from "../../../pages/public/UnAuth/UnAuth";

const PublicRoutes = ({ theme }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    try {
      if (
        location.pathname === "/" ||
        //location.pathname === "/debug" ||
        location.pathname === "/report-crime" ||
        location.pathname === "/all-crimes" ||
        location.pathname === "/view-crime"
      ) {
        navigate("/unauth");
      }
    } catch (e) {
      console.log(e);
    }
  }, [location, navigate]);

  return (
    <Container
      className="d-flex justify-content-center pt-4"
      style={{
        minHeight: "100vh",
        backgroundColor: "#0e0f14",
        maxWidth: "400px",
        border: "1px solid black",
      }}
      fluid
    >
      <Row className="w-100 d-flex justify-content-center">
        <Col className="w-100">
          <Routes>
            <Route path="/signup" element={<Signup theme={theme} />} />
            <Route path="/login" element={<Login theme={theme} />} />
            <Route
              path="/forgot-password"
              element={<ForgotPassword theme={theme} />}
            />
            <Route path="/unauth" element={<UnAuth theme={theme} />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Col>
      </Row>
    </Container>
  );
};

export default PublicRoutes;
