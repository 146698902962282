import React, { useState } from "react";
import { Spinner } from "react-rainbow-components";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import { useEffect } from "react";

const MapMain = ({
  zoom,
  userCurrentPosition,
  isLoaded,
  mapRef,
  onLoad,
  onUnmount,
  location,
  mapDrag,
  setMapDrag,
  mapContainerStyle,
  allEntries,
  getSelectedMarker,
}) => {
  const [mapCenter, setMapCenter] = useState(null);
  const [mapReady, setMapReady] = useState(false);

  const mapReadyStatus = () => {
    setMapReady(true);
  };

  const updateMarkerPosition = () => {
    const { current } = mapRef;
    const currentMapCenter = current.state.map.center;
    setMapDrag({
      lat: currentMapCenter.lat(),
      lng: currentMapCenter.lng(),
    });
  };

  useEffect(() => {
    if (userCurrentPosition) {
      setMapCenter({
        lat: userCurrentPosition.Latitude,
        lng: userCurrentPosition.Longitude,
      });
    }

    if (location) {
      setMapCenter(location);
    }

    if (setMapDrag) {
      setMapDrag({
        lat: userCurrentPosition.Latitude,
        lng: userCurrentPosition.Longitude,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCurrentPosition]);

  return isLoaded ? (
    mapCenter && (
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={mapCenter}
        zoom={userCurrentPosition.trueposition ? zoom : 4}
        clickableIcons={false}
        ref={mapRef}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onDrag={setMapDrag && updateMarkerPosition}
        onTilesLoaded={mapReadyStatus}
        
      >
        {mapReady && allEntries ? (
          allEntries.map((entry) => {
            return (
              <MarkerF
                key={entry._id}
                id={entry._id}
                position={entry.location}
                onClick={(e) => getSelectedMarker(e, entry._id)}
              />
            );
          })
        ) : (
          <MarkerF position={mapDrag} />
        )}
      </GoogleMap>
    )
  ) : (
    <div>
      <Spinner size="large" type="arc" variant="brand" />
    </div>
  );
};

export default MapMain;
