import { useState } from "react";
import { Offcanvas, Image, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import MainButton from "../../Buttons/MainButton";
import packageJSON from "../../../../package.json";

const NavigationBar = ({ theme, handleLogout }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Container className="mb-2 px-0">
      <Row>
        <Col>
          <Image
            className="mt-3"
            src="/images/icons/menu.svg"
            alt="Burger Menu"
            onClick={handleShow}
            width="30px"
          />
        </Col>
        <Col>
          <Offcanvas
            show={show}
            onHide={handleClose}
            style={{ backgroundColor: `${theme?.color?.backgroundSecondary}` }}
          >
            <Offcanvas.Header className="mt-3" closeButton>
              <Link to="/" onClick={handleClose}>
                <Image src={theme?.icons?.main} alt="Logo" width="300px" />
              </Link>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Container fluid>
                <Row>
                  <Col>
                    <MainButton
                      onClick={handleLogout}
                      style={{
                        backgroundColor: theme?.button?.main,
                        border: `2px solid ${theme?.color?.third}`,
                      }}
                    >
                      Log Out
                    </MainButton>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-5 text-center">v{packageJSON.version}</Col>
                </Row>
                <Row>
                  <Col className="mt-5 text-center">
                    Powered by
                    <div>
                      <Image
                        src={theme?.icons?.main}
                        alt="Logo"
                        width="130px"
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Offcanvas.Body>
          </Offcanvas>
        </Col>
      </Row>
    </Container>
  );
};

export default NavigationBar;
