import React from "react";
import { Image } from "react-bootstrap";

const PublicLogo = ({ src }) => {
  return (
    <div className="d-flex align-items-center justify-content-center mb-4">
      <Image src={src} alt="Main Logo" width="300px" />
    </div>
  );
};

export default PublicLogo;
