import React, { useEffect } from "react";
import { Card, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const UnAuth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/login");
    }, 1500);
  }, [navigate]);

  return (
    <>
      <Card>
        <Card.Body>
          <h2 className="text-dark text-center mt-2 mb-4">Unautherised</h2>
          <div className="w-100 text-dark text-center mt-4 mb-2">
            <div className="mb-2">
              <Spinner animation="border" variant="dark" />
            </div>
            Redirecting to login page...
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default UnAuth;
